.outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: -999;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s, z-index 0s 0.3s;
  overflow: auto;
}

.outer.tour-stake-your-stakes-dig-up {
  background-color: rgba(0, 0, 0, 0);
}

.outer.visible {
  z-index: 999;
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.3s, z-index 0s;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 24px;
}

@media (max-width: 440px) {
  .container {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (max-width: 360px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.container::after {
  content: '';
  position: absolute;
  z-index: -99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(80, 49, 44, 0.92);
  opacity: 0;
  transition: opacity 0.3s;
}

.tour-stake-your-stakes-dig-up .container::after {
  z-index: 10;
  opacity: 1;
  transition: opacity 0s;
}

.inner {
  width: 100%;
  max-width: 444px;
  position: relative;
}

.head {
  width: 100%;
  height: 47px;
  position: relative;
  z-index: 3;
  background: url('../../../../../../public/assets/diggy-game/dig-modal-head.png')
    0 0 no-repeat;
  background-size: 100% 100%;
}

.close {
  position: absolute;
  top: 11px;
  right: 14px;
  cursor: pointer;
  font-size: 20px;
}

.close svg {
  display: block;
}

.body {
  margin: -14px 29px 0 24px;
  padding: 25px 30px 57px;
  background: #f6eab0
    url('../../../../../../public/assets/diggy-game/dig-modal-body.png') 50% 50%
    no-repeat;
  background-size: 100% auto;
  overflow: hidden;
}

@media (max-width: 440px) {
  .body {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 360px) {
  .body {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.heading {
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #50312c;
  position: relative;
  margin: 0 auto 24px;
  width: fit-content;
}

.heading::before,
.heading::after {
  content: '';
  position: absolute;
  height: 3px;
  border-radius: 2px;
  background: #d9bc80;
  top: 0;
  right: calc(100% + 14px);
  top: calc(50% - 2px);
  width: 500px;
}

.heading::after {
  right: auto;
  left: calc(100% + 14px);
}

.stakes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  margin: 0 0 24px;
}

.stake {
  width: 50px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  outline: 2px solid #d1a156;
  border-radius: 3px;
  transition: outline 0.3s;
  cursor: pointer;
}

.stakeActive.stake {
  outline: 4px solid #50312c;
}

.stake img {
  position: absolute;
  top: -5px;
  left: -5px;
  width: calc(100% + 10px);
}

.stakeCell {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1px;
  position: relative;
  z-index: 9;
}

.stakeCellNum {
  min-width: 19.13px;
  height: 19.13px;
  background: #fff;
  border: 1.36639px solid #50312c;
  padding: 0 5px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 13.2634px;
  letter-spacing: -0.03em;
  color: #50312c;
  border-radius: 10px;
}

.stakeCellDelimiter svg {
  display: block;
}

.stakeInfo {
  padding: 24px;
  background: #50312c;
  box-shadow: inset 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin-bottom: 24px;
}

@media (max-width: 360px) {
  .stakeInfo {
    padding-left: 15px;
    padding-left: 15px;
  }
}

.stakeInfoHeading {
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #d9bc80;
  margin-bottom: 10px;
}

.stakeInfoCoins {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  font-family: var(--font-primary);
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #f5f2cc;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 16px;
}

.stakeInfoCoins:last-child {
  margin-bottom: 0;
}

.stakeInfoCoins svg {
  font-size: 40px;
}

.stakeInfoParameters {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-height: 28px;
  margin-bottom: 16px;
}

.stakeInfoParametersInfo {
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #f5f2cc;
}

.stakeInfoParametersEnergy {
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #f5f2cc;
}

.btnOuter {
  position: relative;
  margin: 0 -30px;
  padding: 0 30px;
}

.tour-stake-your-stakes-dig-up .btnOuter {
  z-index: 11;
}

.btn {
  margin: 0 34px;
  width: auto;
}

@media (max-width: 360px) {
  .btn {
    margin: 0;
  }
}

.tourIntro {
  position: absolute;
  z-index: 7;
  left: 0;
  right: 0;
  bottom: calc(100% + 10px);
  animation: tourIntroBounce 2s ease infinite;
}

.tourIntro svg {
  display: block;
  color: #f2c64d;
  font-size: 24px;
  margin: 0 auto;
  transform: rotate(90deg);
}

.tourIntroText {
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #f2c64d;
  margin-bottom: 12px;
}

@keyframes tourIntroBounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}
