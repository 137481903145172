.outer {
  padding: 24px 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.input {
  margin-bottom: 20px;
}

.button {
  margin-top: auto;
}

.error {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: -0.03em;
  color: #f00;
}

.removeLogo {
  margin: -18px auto 28px;
  width: fit-content;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #50312c;
}
