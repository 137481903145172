.outer {
  position: relative;
  min-height: 78px;
  padding: 7px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading {
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #d9bc80;
}

.pos {
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.posCoord {
  min-width: 35px;
  height: 35px;
  background: #d9bc80;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 24.2672px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #50312c;
  padding-bottom: 2px;
}

.posDelim svg {
  display: block;
  color: #50312c;
}
