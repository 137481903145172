.outer {
  --_delay: 0.3s;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: calc(var(--client-height, 100vh) + 20px);
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0);
  transition: background-color var(--_delay), top 0s var(--_delay);
  pointer-events: none;
}

.visible {
  top: 0;
  pointer-events: all;
  background-color: rgba(0, 0, 0, 0.32);
  transition: background-color var(--_delay), top 0s;
}

.inner {
  position: absolute;
  left: 3px;
  top: calc(var(--client-height, 100vh) + 20px);
  width: calc(100% - 6px);
  height: calc(100% - var(--header-height, 93px) - 30px);
  background: #f6eab0
    url('../../../../public/assets/diggy-game/dig-modal-body.png') 50% 50%
    no-repeat;
  background-size: 100% auto;
  transition: top var(--_delay);
  padding: 30px 16px;
  overflow: auto;
}

.visible .inner {
  top: calc(var(--header-height, 93px) + 30px);
}

.closeBtn {
  position: absolute;
  top: 11px;
  right: 12px;
  cursor: pointer;
  z-index: 999;
}

.closeBtn svg {
  display: block;
  font-size: 20px;
}

.imageOuter {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto 12px;
  overflow: hidden;
  border-radius: 20px;
}

.imageOuter img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nameOuter {
  text-align: center;
  margin-bottom: 6px;
}

.nameOuter a {
  font-weight: 800;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #db9f59;
}

.socOuter {
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #50312c;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.socOuter svg {
  font-size: 24px;
}

.btn {
  width: fit-content;
  margin: 12px auto 0;
  padding: 0 28px;
}

.infoBlocks {
  margin-top: 24px;
}

.infoBlock {
  height: 50px;
  background: #f2f2f2;
  border: 1px solid #50312c;
  border-radius: 16px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #000;
}

.infoBlock div:first-child {
  font-family: var(--font-krabuler);
  font-weight: 400;
}

.heading {
  margin-top: 28px;
  font-family: var(--font-krabuler);
  font-weight: 400;
  color: #db9f59;
}
