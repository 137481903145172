.outer {
  padding: 24px 16px;
}

.heading {
  margin-top: 28px;
}

.heading:first-child {
  margin-top: 0;
}
