.quests-view {
  padding: 20px 16px;
  height: 100%;
}

.quests-view__empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quests-view__empty-text {
  color: #50312c;
  font-weight: 900;
  font-size: 16px;
}

.quests-view__item {
  margin-bottom: 8px;
  padding: 15px 16px 15px 14px;
  background: #f2f2f2;
  border: 1px solid #50312c;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 14px;
}

.quests-view__item-icon {
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 10px;
  object-fit: cover;
}

.quests-view__item-text {
  margin-bottom: 4px;
  font-weight: 800;
  font-size: 16px;
  line-height: 21.82px;
  color: #50312c;
}

.quests-view__item-amount {
  display: flex;
  align-items: center;
}

.quests-view__item-amount-icon {
  font-size: 16px;
  margin-right: 2px;
}

.quests-view__item-amount-value {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #50312c;
}

.quests-view__item-btn {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  margin-left: auto;
  background: #184a3f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 29px;
  color: #db9f59;
}

a.quests-view__item-btn {
  cursor: pointer;
}

.quests-view__item--completed .quests-view__item-btn {
  font-size: 24px;
}
