.block {
  background: #f2f2f2;
  border: 1px solid #50312c;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 8px;
  position: relative;
}

.block:last-child {
  margin-bottom: 0;
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: #50312c;
  margin-bottom: 4px;
}

.row {
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon {
  font-size: 44px;
  flex-shrink: 0;
}

@media (max-width: 420px) {
  .icon {
    font-size: 35px;
  }
}

@media (max-width: 390px) {
  .icon {
    font-size: 32px;
  }
}

@media (max-width: 360px) {
  .icon {
    font-size: 30px;
  }
}

.amount {
  font-weight: 900;
  font-size: 50px;
  line-height: 100%;
}

@media (max-width: 540px) {
  .amount {
    font-size: 45px;
  }
}

@media (max-width: 500px) {
  .amount {
    font-size: 40px;
  }
}

@media (max-width: 455px) {
  .amount {
    font-size: 35px;
  }
}

@media (max-width: 420px) {
  .amount {
    font-size: 33px;
  }
}

@media (max-width: 390px) {
  .amount {
    font-size: 30px;
  }
}

@media (max-width: 360px) {
  .amount {
    font-size: 26px;
  }
}

.variant-orange .amount {
  color: #db9f59;
}

.variant-green .amount {
  color: #184a3f;
}

.amount span {
  opacity: 0.32;
}

.boostFactors {
  position: absolute;
  height: 21px;
  top: 12px;
  right: 17px;
  background: #a8866b;
  border-radius: 8px;
  display: flex;
}

.boostFactorsWeek {
  padding: 0 11px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.boostFactorsWeekLabel,
.boostFactorsSquadLabel {
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #50312c;
}

.boostFactorsWeekValue {
  font-weight: 800;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #184a3f;
}

.boostFactorsSquad {
  background: #db9f59;
  border-radius: 8px;
  padding: 0 9px 0 7px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.boostFactorsSquadValue {
  font-weight: 800;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #f2f2f2;
}

.boostFactorsSquad + .boostFactorsWeek {
  padding-left: 3px;
}
