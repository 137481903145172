.header {
  background: #50312c;
  padding: 16px 0 22px;
  position: relative;
}

@media (max-height: 785px) {
  .header {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.header::after {
  content: '';
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(80, 49, 44, 0.92);
  opacity: 0;
  transition: opacity 0.3s;
}

.header.disabled::after {
  z-index: 2;
  opacity: 1;
}

.heading {
  position: relative;
  z-index: 1;
  text-align: center;
  font-family: var(--font-secondary);
  font-weight: 400;
  color: #fff;
  font-size: 24px;
  line-height: 27.48px;
}

.leftIcon {
  position: absolute;
  z-index: 1;
  font-size: 44px;
  color: #fff;
  left: 16px;
  top: 11px;
  cursor: pointer;
}

.tabs {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  margin: 20px 0 -22px;
}

@media (max-height: 785px) {
  .tabs {
    margin: 10px 0 -12px;
  }
}

.tab {
  flex: 1;
  color: #db9f59;
  font-weight: 900;
  font-size: 16px;
  line-height: 21.82px;
  text-align: center;
  border-bottom: 4px solid transparent;
  padding-bottom: 3px;
}

.tabActive {
  border-bottom-color: #db9f59;
  color: #fff;
  font-size: 14px;
  line-height: 19.1px;
}
