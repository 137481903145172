.item {
  color: #50312c;
  cursor: pointer;
  flex-basis: 1px;
  flex-grow: 1;
  min-width: 1px;
  padding: 10px 0px;
  background: #7e4f40;
  box-shadow: inset 0px 4px 0px rgba(255, 255, 255, 0.25);
  border-radius: 12px 12px 0px 0px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.active {
  background: #f6eab0;
  box-shadow: inset 0px 4px 0px #fffae0;
}

.icon {
  display: block;
  margin: 0 auto 2px;
  font-size: 40px;
}

@media (max-height: 785px) {
  .icon {
    font-size: 30px;
  }
}

.label {
  text-align: center;
  font-family: var(--font-cygre);
  font-weight: 900;
  font-size: 13px;
  line-height: 23px;
  text-transform: uppercase;
  color: #50312c;
}

@media (max-height: 785px) {
  .label {
    font-size: 12px;
  }
}
