.outer {
  height: 100vh;
  background-color: #a8866b;
  display: flex;
  flex-direction: column;
}

.inner {
  flex: 1;
  overflow: auto;
}
