.outer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 56px;
  background: #50312c;
  box-shadow: inset 0px 6px 0px rgba(255, 255, 255, 0.25),
    inset 0px -8px 0px #2f1814;
  border-radius: 12px;
  font-weight: 800;
  font-size: 24px;
  letter-spacing: -0.03em;
  color: #fff2e3;
  flex-shrink: 0;
  transition: all 0.3s;
  cursor: pointer;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
}

.variant-green {
  background: #77b40a;
  box-shadow: inset 0px 6px 0px rgba(255, 255, 255, 0.25),
    inset 0px -8px 0px #447e17;
}

.disabled {
  background: #8c6b57;
  pointer-events: none;
  box-shadow: none;
}

.variant-green.disabled {
  background: #9bc75c;
}
