.item {
  margin-bottom: 8px;
  background-color: #f2f2f2;
  border: 1px solid #2a2a31;
  border-radius: 20px;
  padding: 16px 20px;
  overflow: hidden;
  position: relative;
}

.number {
  font-family: var(--font-thirdly);
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.03em;
  color: #db9f59;
}

.text {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #50312c;
}

.icon {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.icon svg {
  display: block;
  height: 100%;
}
