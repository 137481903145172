.referal-view {
  padding: 24px 16px;
}

.referal-view__share {
  background: #f2f2f2;
  border: 1px solid #50312c;
  padding: 16px;
  margin-bottom: 24px;
  border-radius: 20px;
}

.referal-view__share-info {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.referal-view__share-info span {
  color: #db9f59;
  font-weight: 800;
}

.referal-view__share-info-friends,
.referal-view__share-info-lvl {
  font-size: 16px;
  line-height: 22px;
  color: #50312c;
  letter-spacing: -0.03em;
}

.referal-view__share-info-lvl {
  color: rgba(24, 74, 63, 0.32);
  font-weight: 500;
}

.referal-view__share-earned {
  margin-bottom: 16px;
  display: flex;
  align-items: flex-end;
  gap: 4px;
}

.referal-view__share-earned-value {
  font-weight: 900;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #db9f59;
}

.referal-view__share-earned-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #50312c;
  padding-bottom: 3px;
}

.referal-view__share-btn {
  display: block;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  background-color: #184a3f;
  border-radius: 12px;
  font-family: var(--font-thirdly);
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  color: #db9f59;
}

.referal-view__share-btn svg {
  font-size: 24px;
}

.referal-view__heading {
  margin-bottom: 16px;
}
