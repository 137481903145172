.outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -99999;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #f1da8e url('../../../../../public/assets/tour-intro-bg.png') 50%
    50%;
  background-size: cover;
  display: flex;
  align-items: center;
  padding: 23px;
  opacity: 0;
  transition: opacity 0.3s, z-index 0s, 0.3s;
}

.outer.visible {
  opacity: 1;
  z-index: 99999;
  transition: opacity 0.3s, z-index 0s;
}

.heading {
  font-family: var(--font-secondary);
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #fff;
  position: absolute;
  top: 63px;
  left: 0;
  width: 100%;
}

.text {
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #a8866b;
  width: 100%;
}

.text span {
  color: #f2c64d;
}

.arrow {
  position: absolute;
  right: 25px;
  bottom: 22px;
  width: 56px;
  height: 56px;
  background: #360614;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #f1da8e;
  cursor: pointer;
}
