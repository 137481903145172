.outer {
  margin-bottom: 30px;
}

.inner {
  width: 170px;
  height: 170px;
  background: rgba(242, 242, 242, 0.44);
  border: 1px dashed #50312c;
  border-radius: 20px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.label {
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.03em;
  color: #50312c;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label input {
  position: absolute;
  top: -1000px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
