.outer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 100px;
  aspect-ratio: 1;
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  background: #fff;
  filter: blur(3px);
  mix-blend-mode: darken;
}

.loader:before {
  content: '';
  margin: auto;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #50312c;
  background: currentColor;
  box-shadow: -30px 0, 30px 0, 0 30px, 0 -30px;
  animation: app-loader-anim 1s infinite alternate;
}
@keyframes app-loader-anim {
  90%,
  100% {
    box-shadow: -10px 0, 10px 0, 0 10px, 0 -10px;
    transform: rotate(180deg);
  }
}
