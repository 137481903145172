.outer {
  display: flex;
  align-items: center;
  padding: 16px;
  color: #50312c;
  margin-bottom: 8px;
  background: #f2f2f2;
  border-radius: 20px;
  border: 1px solid #50312c;
}

.variant-large {
  padding: 20px;
}

.avatar {
  width: 44px;
  height: 44px;
  border-radius: 12px;
  overflow: hidden;
  margin-right: 10px;
}

.avatarImg {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.body {
  padding-right: 20px;
}

.number {
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #c4a186;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  margin-right: 16px;
  color: #50312c;
  flex-shrink: 0;
  margin-left: auto;
}

.position-1 .number,
.position-2 .number,
.position-3 .number {
  border: none;
  color: #fff;
  padding: 1px 0 0 1px;
}

.position-1 .number {
  background: #f1b51c;
}

.position-2 .number {
  background: #ced3d9;
}

.position-3 .number {
  background: #9d5700;
}

.name {
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #50312c;
  margin-bottom: 6px;
}

.onlyName .name {
  margin-bottom: 0;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
}

.scoreOuter {
  display: flex;
  align-items: center;
  gap: 5px;
}

.score {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: rgba(80, 49, 44, 0.5);
}

.icon {
  position: relative;
  top: -1px;
}

.icon svg {
  font-size: 16px;
  display: block;
}
