@font-face {
  font-family: 'Cygre';
  font-weight: 900;
  src: url('../../../public/assets/fonts/Cygre-Black.woff2') format('woff2'),
    url('../../../public/assets/fonts/Cygre-Black.woff') format('woff'),
    url('../../../public/assets/fonts/Cygre-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Krabuler';
  font-weight: 400;
  src: url('../../../public/assets/fonts/RFKrabuler-Regular.woff2')
      format('woff2'),
    url('../../../public/assets/fonts/RFKrabuler-Regular.woff') format('woff'),
    url('../../../public/assets/fonts/RFKrabuler-Regular.ttf')
      format('truetype');
}

:root {
  --font-primary: 'Nunito', sans-serif;
  --font-secondary: 'Titan One', sans-serif;
  --font-thirdly: 'Inter', sans-serif;
  --font-cygre: Cygre, sans-serif;
  --font-krabuler: Krabuler, sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  color: inherit;
  border: none;
  background: transparent;
  outline: none;
}

body {
  font-family: var(--font-primary);
  font-weight: 500;
  font-style: normal;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

.heading-primary {
  font-family: var(--font-secondary);
  font-weight: 400;
  color: #fff;
  font-size: 24px;
  line-height: 27.48px;
}

.scroll-area::-webkit-scrollbar {
  width: 10px;
}

.scroll-area::-webkit-scrollbar-track {
  background: #50312c;
  box-shadow: inset 0px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.scroll-area::-webkit-scrollbar-thumb {
  background: #d9bc80;
  box-shadow: 0px 8px 0px rgba(0, 0, 0, 0.25),
    inset 0px 4px 0px rgba(255, 255, 255, 0.25);
  border-radius: 4px;
}
