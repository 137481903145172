.outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  z-index: -999;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s, z-index 0s 0.3s;
}

.outer.visible {
  z-index: 999;
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.3s, z-index 0s;
}

.inner {
  width: 100%;
  max-width: 362px;
  background: url('../../../../../../public/assets/diggy-game/intro-modal-bg.png')
    0 0 no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding: 24px 0 0 0;
}

.close {
  position: absolute;
  top: 32px;
  right: 31px;
  cursor: pointer;
}

.close svg {
  display: block;
}

.heading,
.footer,
.content {
  font-family: var(--font-krabuler);
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #50312c;
}

.heading {
  font-size: 32px;
}

.footer {
  font-size: 17px;
  padding-bottom: 28px;
}

.content {
  font-size: 17px;
  padding: 41px 59px 40px 32px;
  text-align: left;
}

@media (max-width: 365px) {
  .content {
    padding-right: 32px;
  }
}

.content span {
  color: #f6eab0;
}
