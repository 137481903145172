.outer {
  display: block;
  width: 100%;
}

.label {
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #fff;
  margin-bottom: 8px;
}

.input {
  display: block;
  width: 100%;
  outline: none;
  height: 56px;
  background: #f2f2f2;
  border: 1px solid #50312c;
  border-radius: 20px;
  padding: 0 18px;
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 20px;
  letter-spacing: -0.03em;
  color: #50312c;
}

.input::placeholder {
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 20px;
  letter-spacing: -0.03em;
  color: #a1918f;
}
