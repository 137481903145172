.outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: -999;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s, z-index 0s 0.3s;
  overflow: auto;
}

.outer.tour-stake-start-staking,
.outer.tour-stake-choose-stake-level,
.outer.tour-stake-stake-coins,
.outer.tour-stake-rob-stake-choose {
  background-color: rgba(0, 0, 0, 0);
}

.outer.visible {
  z-index: 999;
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.3s, z-index 0s;
}

.container {
  position: relative;
  width: 100%;
  padding: 24px;
}

@media (max-width: 465px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.container::after {
  content: '';
  position: absolute;
  z-index: -99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(80, 49, 44, 0.92);
  opacity: 0;
  transition: opacity 0.3s;
}

.tour-stake-start-staking .container::after,
.tour-stake-choose-stake-level .container::after,
.tour-stake-stake-coins .container::after,
.tour-stake-rob-stake-choose .container::after {
  z-index: 5;
  opacity: 1;
  transition: opacity 0s;
}

.inner {
  width: 100%;
  max-width: 444px;
}

.head {
  width: 100%;
  height: 47px;
  position: relative;
  z-index: 3;
  background: url('../../../../../../public/assets/diggy-game/dig-modal-head.png')
    0 0 no-repeat;
  background-size: 100% 100%;
}

.close {
  position: absolute;
  top: 11px;
  right: 14px;
  cursor: pointer;
  font-size: 20px;
}

.close svg {
  display: block;
}

.body {
  margin: -14px 29px 0 24px;
  padding: 10px 30px 36px;
  background: #f6eab0
    url('../../../../../../public/assets/diggy-game/dig-modal-body.png') 50% 50%
    no-repeat;
  background-size: 100% auto;
  overflow: hidden;
}

@media (max-width: 465px) {
  .body {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (max-width: 400px) {
  .body {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.heading {
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #50312c;
  position: relative;
  margin: 0 auto 16px;
  width: fit-content;
}

.heading::before,
.heading::after {
  content: '';
  position: absolute;
  height: 3px;
  border-radius: 2px;
  background: #d9bc80;
  top: 0;
  right: calc(100% + 30px);
  top: calc(50% - 2px);
  width: 500px;
}

.heading::after {
  right: auto;
  left: calc(100% + 30px);
}

.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 24px;
}

.cellPic {
  width: 116px;
  height: 116px;
  overflow: hidden;
  border: 8px solid #d9bc80;
  border-radius: 6px;
  position: relative;
  flex-shrink: 0;
}

@media (max-width: 365px) {
  .cellPic {
    width: 96px;
    height: 96px;
  }
}

.cellPic img {
  position: absolute;
  top: -5px;
  left: -5px;
  width: calc(100% + 10px);
  height: auto;
}

.cellPos {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.cellPosItem {
  background: #d9bc80;
  height: 35px;
  min-width: 35px;
  padding: 0 12px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 24.2672px;
  line-height: 100%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #50312c;
  border-radius: 18px;
}

.cellPosDelimiter svg {
  display: block;
}

.cellPosAvailable {
  margin-top: 8px;
}

.cellPosAvailableHeading {
  margin-bottom: 4px;
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #50312c;
}

.cellPosAvailableAmount {
  display: flex;
  align-items: center;
  padding: 6px 16px;
  gap: 3px;
  background: #50312c;
  border-radius: 12px;
  font-family: var(--font-primary);
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #f5f2cc;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
}

@media (max-width: 365px) {
  .cellPosAvailableAmount {
    font-size: 18px;
    padding: 4px 14px;
  }
}

@media (max-width: 340px) {
  .cellPosAvailableAmount {
    font-size: 16px;
    padding: 4px 10px;
  }
}

.cellPosAvailableAmount svg {
  font-size: 24px;
  flex-shrink: 0;
}

.cellInfo {
  padding: 24px;
  background: #50312c;
  box-shadow: inset 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin-bottom: 24px;
}

.actionHeading {
  margin-bottom: 16px;
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #d9bc80;
  text-align: center;
}

.actionBlock {
  margin-bottom: 16px;
}

.tour-stake-start-staking .actionBlockStake,
.tour-stake-rob-stake-choose .actionBlockRob {
  position: relative;
  z-index: 7;
}

.actionBlockTourHint {
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc(100% + 10px);
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #f2c64d;
  animation: tourHintBounce 2s ease infinite;
}

@keyframes tourHintBounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}

.actionBlockLabel {
  margin-bottom: 8px;
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #3c2521;
  transition: color 0.3s;
}

.actionBlockActive .actionBlockLabel {
  color: #d9bc80;
}

.actionBlockInner {
  display: flex;
  align-items: center;
  padding: 0 8px;
  gap: 8px;
  height: 35px;
  background: #50312c;
  border: 2px solid #3c2521;
  border-radius: 12px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.actionBlockActive .actionBlockInner {
  border-color: #d9bc80;
}

.actionBlockIcon {
  font-size: 23px;
  flex-shrink: 0;
}

.actionBlockIcon svg {
  display: block;
}

.actionBlockCheck {
  margin-left: auto;
  font-size: 23px;
  flex-shrink: 0;
  cursor: pointer;
}

.actionBlockCheck svg {
  display: block;
}

.actionBlockCheck svg path {
  opacity: 0;
  transition: opacity 0.3s;
}

.actionBlockActive .actionBlockCheck svg path {
  opacity: 1;
}

.actionBlockText {
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #3c2521;
  transition: color 0.3s, text-shadow 0.3s;
  box-shadow: none;
}

.actionBlockActive .actionBlockText {
  color: #f5f2cc;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
}

.actionBlockInput {
  flex-grow: 1;
  height: 23px;
  font-family: var(--font-primary);
  font-weight: 900;
  font-size: 20px;
  color: #3c2521;
  text-shadow: none;
  transition: color 0.3s, text-shadow 0.3s;
  -moz-appearance: textfield;
  appearance: textfield;
}

.actionBlockInput:disabled {
  pointer-events: none;
}

.actionBlockInput::placeholder {
  color: #3c2521;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.actionBlockInput::-webkit-outer-spin-button,
.actionBlockInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.actionBlockActive .actionBlockInput {
  color: #f5f2cc;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
}

.actionBlockActive .actionBlockInput::placeholder {
  opacity: 1;
}

.tour-stake-choose-stake-level .cellTable,
.tour-stake-rob-stake-choose .cellTable {
  position: relative;
  z-index: 7;
}

.cellTableHeading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #d9bc80;
}

.cellTableHeading div {
  transition: color 0.3s;
}

.cellTableDisabled .cellTableHeading div:nth-child(2) {
  color: #3c2521;
}

.cellTableLevel {
  margin: 0 -10px 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  border: 2px solid transparent;
  border-radius: 12px;
  transition: border-color 0.3s;
}

.cellTableLevel:last-child {
  margin-bottom: 0;
}

.cellTableLevelActive.cellTableLevel {
  border-color: #d9bc80;
}

.cellTableLevelDisabled.cellTableLevel {
  opacity: 0.5;
  pointer-events: none;
}

.cellTableLevelCellLevel {
  width: 28px;
  height: 28px;
  background: #50312c;
  border: 2.5px solid #d9bc80;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.03em;
  color: #d9bc80;
  border-radius: 50%;
  padding-bottom: 2px;
  transition: border-color 0.3s, color 0.3s;
}

.cellTableLevelActive .cellTableLevelCellLevel {
  border-color: #f5f2cc;
  color: #f5f2cc;
}

.cellTableLevelCellProfit,
.cellTableLevelCellCost {
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #d9bc80;
  transition: color 0.3s;
}

@media (max-width: 340px) {
  .cellTableLevelCellProfit {
    font-size: 14px;
  }
}

.cellTableLevelActive .cellTableLevelCellProfit,
.cellTableLevelActive .cellTableLevelCellCost {
  color: #f5f2cc;
}

.cellTableDisabled .cellTableLevelCellProfit {
  color: #3c2521;
}

.tour-stake-stake-coins .btnOuter,
.tour-stake-rob-stake-choose .btnOuter {
  position: relative;
  z-index: 7;
}

.btn {
  margin: 0 34px;
  width: auto;
}
