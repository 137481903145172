.outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  z-index: -999;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s, z-index 0s 0.3s;
}

.outer.visible {
  z-index: 9999;
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.3s, z-index 0s;
}

.inner {
  width: 100%;
  max-width: 354px;
  background: url('../../../../../../public/assets/diggy-game/stolen-treasure-modal-bg.png')
    0 0 no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding: 10px 0 11px;
}

.heading {
  font-family: var(--font-krabuler);
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.408px;
  color: #50312c;
  text-align: center;
  margin-bottom: 10px;
  position: relative;
  z-index: 9;
}

.amount {
  position: relative;
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 3px;
  height: 53px;
  padding: 0 10px;
  margin: 0 auto;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 100%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #f6eab0;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  z-index: 9;
}

.amount:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  background: #f75252;
  filter: blur(35px);
}

.btn {
  width: 162px;
  margin: 0 auto;
  height: 35px;
  position: relative;
  z-index: 9;
}

.coordinates {
  margin-bottom: 5px;
  position: relative;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.coordinate {
  display: flex;
  align-items: center;
  gap: 1px;
}

.coordinateItem {
  height: 19px;
  min-width: 19px;
  background: #fff;
  border: 1.36639px solid #50312c;
  padding: 0 5px;
  border-radius: 10px;
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 13.2634px;
  line-height: 100%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #50312c;
  text-align: center;
}

.coordinateSeparator svg {
  display: block;
}
