.outer {
  position: relative;
  padding: 28px 16px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.outer::after {
  content: '';
  position: absolute;
  z-index: -3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(80, 49, 44, 0.92);
  opacity: 0;
  transition: opacity 0.3s;
}

.outer.tour-claim-start::after,
.outer.tour-claim-go-to-stake::after {
  z-index: 5;
  opacity: 1;
  transition: opacity 0s;
}

.body {
  margin: 0 0 auto;
  width: 100%;
}

.intro,
.energy {
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  color: #50312c;
}

.energy {
  margin: 16px auto 0;
  width: fit-content;
  position: relative;
}

.energyHidden {
  visibility: hidden;
}

.energyVisible {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: right;
}

.energySign {
  position: absolute;
  top: 0;
  right: -27px;
}

.intro {
  text-align: center;
  margin-top: 16px;
}

.claimOuter {
  position: relative;
  z-index: 3;
}

.tour-claim-start .claimOuter {
  z-index: 7;
}

.claim {
  width: 100%;
  height: 48px;
  background-color: #50312c52;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
  pointer-events: none;
}

.tour-claim-start .claim {
  height: 56px;
  box-shadow: 0px 0px 20.9px 4px rgba(242, 198, 77, 0.2),
    inset 0px 6px 0px rgba(255, 255, 255, 0.25), inset 0px -8px 0px #2f1814;
}

.claim.claimActive {
  background: #50312c;
  pointer-events: all;
}

.claimInner {
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-thirdly);
  font-weight: 800;
  font-size: 20px;
  line-height: 100%;
  color: #fff;
}

.tour-claim-start .claimInner {
  font-family: var(--font-primary);
  font-weight: 800;
  font-size: 24px;
  color: #fff2e3;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
}

.claimStripe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #50312c;
}

.tour-claim-start .claimStripe {
  display: none;
}

.tapOuter {
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.tapBox {
  transition: all 0.05s;
}

.tapBoxDisabled {
  pointer-events: none;
  opacity: 0.7;
  filter: grayscale(0.5);
}

.tapBox svg {
  display: block;
}

.tapValue {
  position: absolute;
  transition: all 3s;
  pointer-events: none;
  color: #9d5700;
  font-weight: 800;
  font-size: 22px;
  opacity: 1;
}

.tourInfo {
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc(100% + 25px);
}

.tourInfoHeading {
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #f2c64d;
}

.tourInfoAmount {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #f2f2f2;
}

.tourInfoAmount svg {
  font-size: 42px;
}
