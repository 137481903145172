.outer {
  position: relative;
  background: #50312c;
}

.outer::after {
  content: '';
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(80, 49, 44, 0.92);
  opacity: 0;
  transition: opacity 0.3s;
}

.outer.disabled::after {
  z-index: 2;
  opacity: 1;
}

.inner {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  gap: 4px;
}

.tourIntro {
  position: absolute;
  z-index: 7;
  left: 0;
  right: 0;
  bottom: calc(100% + 10px);
  animation: tourIntroBounce 2s ease infinite;
}

.tourIntro svg {
  display: block;
  color: #f2c64d;
  font-size: 24px;
  margin: 0 auto;
  transform: rotate(90deg);
}

.tourIntroText {
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #f2c64d;
  margin-bottom: 12px;
}

@keyframes tourIntroBounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}
