.outer {
  position: relative;
  width: 100%;
  height: 100%;
  background: url('../../../../public/assets/diggy-game/main-bg.png') 50% 50%
    no-repeat;
  background-size: 100% 100%;
  padding-top: 30px;
  overflow: auto;
}

@media (max-height: 765px) {
  .outer {
    padding-top: 25px;
  }
}

@media (max-height: 735px) {
  .outer {
    padding-top: 15px;
  }
}

.outer::after {
  content: '';
  position: absolute;
  z-index: -99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(80, 49, 44, 0.92);
  opacity: 0;
  transition: opacity 0.3s;
}

.outer.tour-stake-choose-place::after,
.outer.tour-stake-your-stakes-intro::after,
.outer.tour-stake-rob-stake-intro::after {
  z-index: 5;
  opacity: 1;
  transition: opacity 0s;
}

.scroller {
  height: calc(100% + 13px);
  overflow: auto;
  margin-top: -21px;
  padding-top: 21px;
}

@media (max-height: 765px) {
  .scroller {
    height: calc(100% + 8px);
    margin-top: -16px;
  }
}

@media (max-height: 735px) {
  .scroller {
    height: 100%;
    margin-top: -7px;
  }
}

.inner {
  position: relative;
  border: 6px solid #d9bc80;
  border-radius: 10px;
  background: #028af8;
  overflow: hidden;
  margin: 0 46px;
  padding-bottom: calc(100% - 104px);
}

@media (max-width: 425px) {
  .inner {
    margin: 0 25px;
    padding-bottom: calc(100% - 62px);
  }
}

@media (max-width: 375px) {
  .inner {
    margin: 0 15px;
    padding-bottom: calc(100% - 42px);
  }
}

@media (max-width: 340px) {
  .inner {
    margin: 0 5px;
    padding-bottom: calc(100% - 22px);
  }
}

.tour-stake-choose-place .inner,
.tour-stake-rob-stake-intro .inner {
  z-index: 7;
}

.container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.tour-stake-choose-place .cellPlaceInfo,
.tour-stake-rob-stake-intro .cellPlaceInfo {
  z-index: 7;
}

.tour-stake-choose-place .cellPlaceInfoHeading,
.tour-stake-rob-stake-intro .cellPlaceInfoHeading {
  color: #f2c64d;
}

.tour-stake-choose-place .cellPlaceInfoPosDelim svg,
.tour-stake-rob-stake-intro .cellPlaceInfoPosDelim svg {
  color: #d9bc80;
}

.counters {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  margin-bottom: 16px;
}

@media (max-height: 765px) {
  .counters {
    margin-bottom: 15px;
  }
}

@media (max-height: 735px) {
  .counters {
    margin-bottom: 10px;
  }
}

.counter {
  height: 24px;
  min-width: 110px;
  background: #50312c;
  border: 1px solid #d9bc80;
  border-radius: 2px;
  display: flex;
  gap: 3px;
  align-items: center;
  padding: 0 7px 0 14px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #f5f2cc;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  justify-content: flex-end;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 70px 20px;
}

@media (max-width: 375px) {
  .buttons {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 340px) {
  .buttons {
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media (max-height: 765px) {
  .buttonsBtn {
    height: 50px;
  }
}

@media (max-height: 735px) {
  .buttonsBtn {
    height: 40px;
    font-size: 20px;
  }
}

.canDig.tour-stake-choose-place .buttonsBtnSelect,
.tour-stake-rob-stake-intro .buttonsBtnSelect {
  position: relative;
  z-index: 7;
}

.buttonsBtnStakesOuter {
  position: relative;
}

.tour-stake-your-stakes-intro .buttonsBtnStakesOuter {
  z-index: 7;
}

.tourIntro {
  position: absolute;
  z-index: 7;
  left: 0;
  right: 0;
  bottom: calc(100% + 10px);
  animation: tourIntroBounce 2s ease infinite;
}

.tourIntro svg {
  display: block;
  color: #f2c64d;
  font-size: 24px;
  margin: 0 auto;
  transform: rotate(90deg);
}

.tourIntroText {
  font-family: var(--font-krabuler);
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #f2c64d;
  margin-bottom: 12px;
}

@keyframes tourIntroBounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}
