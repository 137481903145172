.banner {
  background: #f2f2f2;
  border: 1px solid #50312c;
  border-radius: 20px;
  padding: 16px;
}

.heading {
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.03em;
  color: #50312c;
  margin-bottom: 4px;
}

.text {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.03em;
  color: #50312c;
  margin-bottom: 16px;
}

.btn {
  height: 44px;
  background: #184a3f;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #db9f59;
  cursor: pointer;
}
